import React from "react";
import SideNavBar from "../home/side-bar/";
const Layout = ({ children }) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-3  ">
        <SideNavBar />
      </div>

      <div className="col-span-12 lg:col-span-9 py-10">{children}</div>
    </div>
  );
};
export default Layout;
