import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./";
import AppRoutes from "./routes";
// import ManageDataTable from "./manage-table";
// import UserDataTable from "./pages/home/user-data-table";
import SideBar from "./pages/home/side-bar/index";
function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
