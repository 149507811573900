import React from "react";

const AddUserModal = ({ isOpen, onClose, onSubmit, onChange, userData }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="bg-white p-8 w-96 rounded-lg z-10">
        <h2 className="text-2xl font-semibold mb-4">Add User</h2>
        <div className="mb-4">
          <input
            type="text"
            name="name"
            value={userData.name}
            placeholder="Enter Name"
            className="border border-gray-300 px-3 py-2 rounded-md w-full"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            value={userData.email}
            placeholder="johndoe@gmail.com"
            className="border border-gray-300 px-3 py-2 rounded-md w-full"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="password"
            value={userData.password}
            placeholder="Enter Password"
            className="border border-gray-300 px-3 py-2 rounded-md w-full"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="confirmPassword"
            value={userData.confirmPassword}
            placeholder="Confirm Password"
            className="border border-gray-300 px-3 py-2 rounded-md w-full"
            onChange={onChange}
          />
        </div>
        <div className="flex justify-end">
          <button
            className="bg-teal-500 text-white px-4 py-2 rounded-md mr-2"
            onClick={onSubmit}
          >
            Save
          </button>
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
