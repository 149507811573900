import React,{useEffect,useState} from 'react'
import {
    EllipsisVerticalIcon,
    Cog6ToothIcon,

} from '@heroicons/react/24/solid'
import Loader from '../component/loader'

const people = [
  { name: 'BlueNose', title: 'Bluenose', addAction: 'View calendar',  },
  { name: 'BlueNose', title: 'Bluenose', addAction: 'View calendar',  },
  { name: 'BlueNose', title: 'Bluenose', addAction: 'View calendar',  },
]
const headings = [
  { title: 'User without logging', id: '1' },
  { title: 'Overall users', id: '2' },
  // { title: 'Calendar', id: '3' },
  { title: <Cog6ToothIcon className="h-5 w-5"></Cog6ToothIcon>, id: '3' },
]

const UserDataTable = () => {
  const [users,setUsers]=useState();
  const [loader,setLoader]=useState(true);
  const [currentPage,setCurrentPage]=useState(1);
  const [usersPerPage] =useState(10);
  const indexOfLastUser =usersPerPage*currentPage;
  const indexOfFirstUser = indexOfLastUser-usersPerPage;
  const currentUsers = users?.slice(indexOfFirstUser,indexOfLastUser);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users?.length / usersPerPage); i++) {
      pageNumbers.push(i);
  }
  const handlePageChange=(pageNumber)=>{
    setCurrentPage(pageNumber)
}
  useEffect(()=>{
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/fetch-users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('fetch users response', res);
          if (res.ok) {
            const response = await res.json();
            setUsers(response.users)
            setLoader(false)
            
          }
        })
        .catch((err)=>{
          console.log("Error fetching users",err)
        })
    } catch (error) {
      console.log("Error fetching users",error)
    }
    },[])
  return (
    <div className="px-10 h-full">
      <div className="sm:flex sm:items-center lg:mt-20">
        <div className="sm:flex-auto w-full">
          <h1 className="flex justify-center lg:justify-start text-4xl font-semibold leading-6 text-gray-900 w-full tex-left">Users info</h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          
            <table className="min-w-full divide-y divide-gray-300">
            
              <thead>
                <tr className='border-gray-200 border rounded-t-sm'>
                  {headings.map((heading) => (
                    <th key={heading.id} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      <p className="group inline-flex">
                        {heading.title}
                      </p>
                    </th>
                  ))}
                 
                </tr>

              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {currentUsers?.map((user) => (
                  <tr key={user._id} className='border border-gray-200 hover:bg-teal-50'>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                      {user.fullname}
                    </td>
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                      {user.fullname}
                      </td>
                    {/* <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500"><button className='px-3 py-2 bg-teal-500 text-white rounded-sm'>Calendar</button></td> */}
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500"><button className='px-2 py-2 text-gray-900 hover:bg-teal-500 hover:text-white rounded-sm'><EllipsisVerticalIcon className='w-5 h-5 hober:text-white bover-bg-teal-500'/></button></td>
                  </tr>
                ))}
                  
              </tbody>
            </table>
          </div>
          <div className="flex justify-center items-end mt-10">
          {pageNumbers.map(number => (
              <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`mx-1 px-4 py-2 border rounded-lg text-sm transition-colors duration-300 ${currentPage === number ? 'bg-teal-500 text-white border-teal-500' : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'}`}
              >
                  {number}
              </button>
          ))}
          </div>
        </div>
      </div>
      {loader && (
  <div className='flex justify-center items-center h-full'>
    <Loader/>
  </div>
)}
    </div>
  )
}
export default UserDataTable